// generated import statements - any manual change made here will be overwritten automatically. 

import ArticlesInsight from '../../Features/Blocks/ArticlesInsight/ArticlesInsight.init.js';

import ContextBanner from '../../Features/Blocks/BreakingNews/ContextBanner/ContextBanner.init.js';

import SignupMultipleNewsletters from '../../Features/Blocks/Newsletters/NewslettersSignup/SignupMultipleNewsletters/SignupMultipleNewsletters.init.js';

import SignupSingleNewsletter from '../../Features/Blocks/Newsletters/NewslettersSignup/SignupSingleNewsletter/SignupSingleNewsletter.init.js';

import SignupStepActions from '../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepActions/SignupStepActions.init.js';

import SignupStepEmail from '../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepEmail/SignupStepEmail.init.js';

import SignupStepFullname from '../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepFullname/SignupStepFullname.init.js';

import SignupStepReceipt from '../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepReceipt/SignupStepReceipt.init.js';

import SignupStepSelectNewsletters from '../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepSelectNewsletters/SignupStepSelectNewsletters.init.js';

import SendCalculationStepActions from '../../Features/Blocks/SendCalculation/Actions/SendCalculationStepActions.init.js';

import VideoBlock from '../../Features/Blocks/Video/VideoBlock.init.js';

import FindCenterContactPersons from '../../Features/FindCenter/Blocks/FindCenterContactPersons/FindCenterContactPersons.init.js';

import FindCenterMapAndEmployees from '../../Features/FindCenter/Blocks/FindCenterMapAndEmployees/FindCenterMapAndEmployees.init.js';

import CenterMap from '../../Features/FindCenter/Blocks/FindMap/CenterMap.init.js';

import JobApplication from '../../Features/JobPosting/Pages/JobApplication/JobApplication.init.js';

import FooterContact from '../../Features/Navigation/Blocks/FooterContact/FooterContact.init.js';

import Menu from '../../Features/Navigation/Menu/Menu.init.js';

import FeedbackBlock from '../../Features/Ratings/FeedbackBlock/FeedbackBlock.init.js';

import FeedbackStepOne from '../../Features/Ratings/FeedbackBlock/FeedbackStepOne/FeedbackStepOne.init.js';

import FeedbackStepThree from '../../Features/Ratings/FeedbackBlock/FeedbackStepThree/FeedbackStepThree.init.js';

import FeedbackStepTwo from '../../Features/Ratings/FeedbackBlock/FeedbackStepTwo/FeedbackStepTwo.init.js';

import AnkerMenu from '../../Foundation/FrontendComponents/AnkerMenu/AnkerMenu.init.js';

import MitNykreditMenu from '../../Foundation/FrontendComponents/MitNykredit/MitNykreditMenu/MitNykreditMenu.init.js';

import Breadcrumbs from '../../Foundation/Navigation/Breadcrumbs/Breadcrumbs.init.js';

import AnnualReportsTable from './components/AnnualReportsTable/AnnualReportsTable.init.js'

import ContactCard from './components/ContactCard/ContactCard.init.js'

import ContactCardOnboarding from './components/ContactCardOnboarding/ContactCardOnboarding.init.js'

import ContactCardPermission from './components/ContactCardPermission/ContactCardPermission.init.js'

import ContactStepComments from './components/ContactStepComments/ContactStepComments.init.js'

import ContactStepContent from './components/ContactStepContent/ContactStepContent.init.js'

import ContactStepIntro from './components/ContactStepIntro/ContactStepIntro.init.js'

import ContactStepNewsletters from './components/ContactStepNewsletters/ContactStepNewsletters.init.js'

import ContactStepOnboardingContent from './components/ContactStepOnboardingContent/ContactStepOnboardingContent.init.js'

import DocumentArchive from './components/DocumentArchive/DocumentArchive.init.js'

import DynamicDocumentList from './components/DynamicDocumentList/DynamicDocumentList.init.js'

import DynamicTable from './components/DynamicTable/DynamicTable.init.js'

import FilterTabs from './components/FilterTabs/FilterTabs.init.js'

import GoogleMapsWithMarkers from './components/GoogleMapsWithMarkers/GoogleMapsWithMarkers.init.js'

import InvestmentFundTable from './components/InvestmentFundTable/InvestmentFundTable.init.js'

import JobPostingsTable from './components/JobPostingsTable/JobPostingsTable.init.js'

import MultiSelectDropdown from './components/MultiSelectDropdown/MultiSelectDropdown.init.js'

import SearchInputField from './components/SearchInputField/SearchInputField.init.js'

import SearchOverlay from './components/SearchOverlay/SearchOverlay.init.js'

import SendCalculation from './components/SendCalculation/SendCalculation.init.js'

import SendCalculationStepContent from './components/SendCalculationStepContent/SendCalculationStepContent.init.js'

import SiteSearching from './components/SiteSearching/SiteSearching.init.js'

import SiteSearchingResult from './components/SiteSearchingResult/SiteSearchingResult.init.js'

import StickyBox from './components/StickyBox/StickyBox.init.js'

import TheForms from './components/TheForms/TheForms.init.js'

import ThemeSwitch from './components/ThemeSwitch/ThemeSwitch.init.js'

import Tooltip from './components/Tooltip/Tooltip.init.js'


export default function(Store){
   ArticlesInsight(Store)
   ContextBanner(Store)
   SignupMultipleNewsletters(Store)
   SignupSingleNewsletter(Store)
   SignupStepActions(Store)
   SignupStepEmail(Store)
   SignupStepFullname(Store)
   SignupStepReceipt(Store)
   SignupStepSelectNewsletters(Store)
   SendCalculationStepActions(Store)
   VideoBlock(Store)
   FindCenterContactPersons(Store)
   FindCenterMapAndEmployees(Store)
   CenterMap(Store)
   JobApplication(Store)
   FooterContact(Store)
   Menu(Store)
   FeedbackBlock(Store)
   FeedbackStepOne(Store)
   FeedbackStepThree(Store)
   FeedbackStepTwo(Store)
   AnkerMenu(Store)
   MitNykreditMenu(Store)
   Breadcrumbs(Store)
   AnnualReportsTable(Store)
   ContactCard(Store)
   ContactCardOnboarding(Store)
   ContactCardPermission(Store)
   ContactStepComments(Store)
   ContactStepContent(Store)
   ContactStepIntro(Store)
   ContactStepNewsletters(Store)
   ContactStepOnboardingContent(Store)
   DocumentArchive(Store)
   DynamicDocumentList(Store)
   DynamicTable(Store)
   FilterTabs(Store)
   GoogleMapsWithMarkers(Store)
   InvestmentFundTable(Store)
   JobPostingsTable(Store)
   MultiSelectDropdown(Store)
   SearchInputField(Store)
   SearchOverlay(Store)
   SendCalculation(Store)
   SendCalculationStepContent(Store)
   SiteSearching(Store)
   SiteSearchingResult(Store)
   StickyBox(Store)
   TheForms(Store)
   ThemeSwitch(Store)
   Tooltip(Store)
}