export default async function (body, url) {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
      .then((res) => {
        if (res.ok) {
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                return res.json();
            } else {
                return res.text();
            }
      } else {
        throw new Error("Didn't work sending the calculation.")
      }
    })
    .then((json) => {
        return json
    })
    .catch((err) => {
      console.error(err)
      return err
    })

  return response
}
