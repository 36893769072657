<script>
import options from "./SendCalculationStepActions.options"
export default options
</script>


<template>
    <div :class="`nyk-signup-step-actions ${spaceTop ? '' : 'nyk-signup-step-actions--no-space-top'} nyk-signup-step-actions--no-prev`">
        <p class="nyk-signup-step__stepcounter">
            This is a test
        </p>

        <button v-on:click="nextStepCallback" class="nyk-signup-step-actions__action nyk-signup-step-actions__action--end">
            <p class="nyk-signup-step-actions__action__text">Send</p>
            <div class="nyk-signup-step-actions__action__button nyk-button nyk-button--link-circle-arrow right">
                <div class="nyk-button__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" role="presentation" fill="none" viewBox="0 0 14 12">
                        <path
                            fill="#07094A"
                            d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"></path>
                    </svg>
                </div>
            </div>
        </button>
    </div>
</template>

