import { ref } from "vue"
import SignupStepEmail from "../SignupStepEmail/SignupStepEmail.vue"
import emailValidator from "../../../../../Frontend/src/foundation/js/inputValidators/emailValidator"
import notEmptyInput from "../../../../../Frontend/src/foundation/js/inputValidators/notEmptyInput"
import SignupStepFullname from "../SignupStepFullname/SignupStepFullname.vue"
import SignupStepReceipt from "../SignupStepReceipt/SignupStepReceipt.vue"
import newslettersSignupSubmit from "../../../../../Frontend/src/foundation/js/signupFlows/newslettersSignupSubmit"
import getGeneralComputedValues from "../../../../../Frontend/src/foundation/js/signupFlows/getGeneralComputedValues"
import handleValidateInputAndProceed from "../../../../../Frontend/src/foundation/js/signupFlows/handleValidateInputAndProceed"

export default {
  name: "SignupSingleNewsletter",
  components: {
    SignupStepEmail,
    SignupStepFullname,
    SignupStepReceipt,
  },
  props: {
    labels: {
      type: Object,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    newsletters: {
      type: Array,
      required: true,
      },
    enableUserTrack: {
        type: Boolean,
        default: false,
        required: false
    }
  },
  setup(props) {
    // this object contains the entire set of inputs available for the complete form.
    const userInput = ref([
      {
        field: "email",
        value: "", // you can prefill the inputfield by adding a value here.
        validator: emailValidator,
        isValid: false,
      },
      {
        field: "firstname",
        value: "",
        validator: notEmptyInput,
        isValid: false,
      },
      {
        field: "lastname",
        value: "",
        validator: notEmptyInput,
        isValid: false,
      },
    ])

    // this object contains stepinformation.
    // all steps are described here
    let stepsConfigAndOrder = ref([
      {
        step: 1, //order - manual number. The order of the array determins the steporder
        name: "SignupStepEmail", // stepname. Must correspond to a component declared under "components"
        completed: false, // are all fields valid?
        texts: { title: props.labels.title, summary: props.labels.summary }, // configuration of texts
      },
      {
        step: 2,
        name: "SignupStepFullname",
        completed: false,
        texts: { title: props.labels.title },
      },
      {
        step: 3,
        name: "SignupStepReceipt",
        completed: false,
        texts: { title: props.labels.title, summary: props.labels.signupStepReceipt },
      },
    ])

    const displayInputError = ref(false)
    const newletterClassName = props.newsletters[0]?.name.replace(" ", "-").toLowerCase()

    const {
      getNamedFieldInputData,
      resetSignupForm,
      currentStepName,
      currentStepTexts,
      hasPreviousStep,
      stepCounterText,
      showFieldErrorMessage,
      returnToPreviousStep,
    } = getGeneralComputedValues(userInput.value, stepsConfigAndOrder.value)

    const validateInputAndProceed = function (inputData) {
      const validSuccess = handleValidateInputAndProceed(
        inputData,
        userInput.value,
        stepsConfigAndOrder.value,
        currentStepName.value,
        submitData
      )

      displayInputError.value = validSuccess
    }

    const submitData = async function (inputData) {
      const body = {
        email: inputData.find((field) => field.field === "email").value,
        firstName: inputData.find((field) => field.field === "firstname").value,
        lastName: inputData.find((field) => field.field === "lastname").value,
        newsletters: [props.newsletters[0].name],
          source: window.location.href,
        enableUserTrack: props.enableUserTrack === "true"
      }
      await newslettersSignupSubmit(body, props.apiUrl)
    }

    return {
      currentStepTexts,
      displayInputError,
      currentStepName,
      getNamedFieldInputData,
      validateInputAndProceed,
      stepCounterText,
      returnToPreviousStep,
      showFieldErrorMessage,
      newletterClassName,
      hasPreviousStep,
      resetSignupForm,
    }
  },
}
